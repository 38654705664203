@keyframes inAnimation {
  from {
    opacity: 0;
    scale: 0.5;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.customSelect .inAnimation {
  animation: inAnimation 0.2s;
}

@keyframes outAnimation {
  from {
    opacity: 1;
    scale: 1;
  }
  to {
    opacity: 0;
    scale: 0.5;
  }
}

.customSelect .outAnimation {
  animation: outAnimation 0.2s;
}
