.Toastify__toast-body {
  gap: 10px !important;
  padding: 10px !important;
}

.Toastify__toast-container {
  min-width: 20rem !important;
  max-width: 700px !important;
  width: fit-content !important;
}
.Toastify__close-button {
  margin-top: 7px !important;
  margin-right: 3px !important;
  margin-left: 10px !important;
  width: 1rem !important;
  > svg {
    height: 1rem !important;
    width: 1rem !important;
  }
}
.test-type-error {
  .Toastify__toast-icon {
    > svg {
      fill: #f54e55;
    }
  }
}
.test-type-success {
  .Toastify__toast-icon {
    > svg {
      fill: #27e09e;
    }
  }
}
.test-type-warning {
  .Toastify__toast-icon {
    > svg {
      fill: #ffb84d;
    }
  }
}

.Toastify__toast-icon {
  margin: 0;
  margin-inline-start: 0 !important;
  padding: 0.7em;
  border-radius: 0.4em;
  width: 36px !important;
}

.Toastify__close-button {
  color: black !important;
}
.dark {
  .Toastify__close-button {
    color: white !important;
  }
  .Toastify__toast-icon {
    background: #131517;
  }
}
