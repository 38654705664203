@import './components/Elements/Notification/notification.css';
@import './components/Elements/User/user.css';
@import './components/ProductSwitch/prodSwitch.css';
@import './components/Toasts/style.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GraphikSemibold';
  src: local('GraphikSemibold'),
    url('./assets/fonts/Graphik/GraphikSemibold.woff2') format('woff2');
}

@font-face {
  font-family: 'GraphikRegular';
  src: local('GraphikRegular'),
    url('./assets/fonts/Graphik/GraphikRegular.woff2') format('woff2');
}

@font-face {
  font-family: 'GraphikMedium';
  src: local('GraphikMedium'),
    url('./assets/fonts/Graphik/GraphikMedium.woff2') format('woff2');
}

@font-face {
  font-family: 'GraphikBold';
  src: local('GraphikBold'),
    url('./assets/fonts/Graphik/GraphikBold.woff2') format('woff2');
}

@layer utilities {
  .scrollbar::-webkit-scrollbar-corner {
    background: transparent;
  }
  .scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 100vh;
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 100vh;
  }

  .dark .scrollbar::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
  }
  .dark .scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.4);
  }
}

.explorer-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}

.explorer-scroll::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.explorer-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #afafaf;
}

@layer base {
  html {
    font-family: 'GraphikRegular';
  }
}

.explorer-scroll::-webkit-scrollbar-track {
  @apply w-[5px] bg-white/50 dark:bg-black/50;
}

.explorer-scroll::-webkit-scrollbar {
  @apply w-[5px] bg-white/50 dark:bg-black/50;
}

.explorer-scroll::-webkit-scrollbar-thumb {
  @apply w-[5px] bg-black/50 transition dark:bg-white/50;
}
