.product-switch-icon {
  @apply h-[30px] w-[30px];
}

.product-switch-text {
  @apply font-normal not-italic;
}

.product-switch-title {
  @apply font-graphikMedium text-[16px] leading-[17.6px];
}

.product-switch-description {
  @apply font-graphikRegular text-[14px] leading-[15.4px];
}
