.notification-wrapper {
  @apply relative top-0 left-[4.33px] flex items-center;
}

.notification-svg {
  @apply h-6 w-6 cursor-pointer;
}
.notification-dot.critical {
  @apply bg-tw-danger-color dark:bg-tw-danger-color-dark;
}
.notification-dot.error {
  @apply bg-tw-warning-color dark:bg-tw-warning-color-dark;
}
.notification-dot.standard {
  @apply bg-tw-main-color dark:bg-tw-main-color-dark;
}

.notification-badge {
  @apply rounded-[4.76px] text-center shadow-[0_1.2px_0_0_rgba(0,0,0,0.5)];
}
.notification-badge.critical {
  @apply bg-tw-danger-color dark:bg-tw-danger-color-dark;
}
.notification-badge.error {
  @apply bg-tw-warning-color dark:bg-tw-warning-color-dark;
}
.notification-badge.standard {
  @apply bg-tw-main-color dark:bg-tw-main-color-dark;
}

.notification-tooltip {
  @apply text-center font-graphikRegular text-[12px] leading-[13px];
}
