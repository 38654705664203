.user-profile-picture {
  @apply mr-[12px] h-[30px] w-[30px] rounded-lg;
}

.user-profile-name {
  @apply font-graphikRegular text-[14px] leading-[16px];
}

.user-profile-logout {
  @apply mb-2 h-14 w-56 rounded-[10px] border-[1.5px] px-4 py-3 shadow-[0_1px_0_rgba(0,0,0,0.2)];
}

.user-profile-logout-text {
  @apply text-start font-graphikRegular text-[14px] leading-[16px];
}

.user-chevron {
  @apply ml-12 h-[8px] w-[13px] -rotate-180;
}
